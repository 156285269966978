export const setupDataLayer = () => {
  const mediaBreakpoint = function(width) {
    if (width > 768) {
      return 'desktop'
    } else if (width > 480) {
      return 'tablet'
    } else {
      return 'mobile'
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    environment: {
      language: 'en',
      mediaBreakpoint: mediaBreakpoint(window.innerWidth),
    },
  })
}

export const initDataTrackEvents = () => {
  window.eventTracking = {
    track: function(category, action, label, value, customVars) {
      window.dataLayer.push({
        event: 'gtmEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
        ...customVars,
      })
    },
  }

  // We provide a `data-track*` element attribute helpers
  document.addEventListener('DOMContentLoaded', function() {
    // data-track-click="category/action/label"
    Array.prototype.slice
      .call(document.querySelectorAll('[data-track-click]'))
      .forEach(function(el) {
        var track = el.getAttribute('data-track-click').split('/')
        el.addEventListener('click', function() {
          window.eventTracking.track(track[0], track[1], track[2])
        })
      })
    // data-track="category/action/label"
    Array.prototype.slice
      .call(document.querySelectorAll('[data-track]'))
      .forEach(function(el) {
        var track = el.getAttribute('data-track').split('/')
        window.eventTracking.track(track[0], track[1], track[2])
      })
  })
}

setupDataLayer()
initDataTrackEvents()
